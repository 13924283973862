import React, { Fragment } from "react";
import HeaderLandingPage from "../../../Templates/LandingPage/HeaderLandingPage";
import QuoteLandingPage from "../../../Templates/LandingPage/QuoteLandingPage";
import { Container } from "reactstrap";
const Home = () => {
  return (
    <Fragment>
      <div className="header-landing-page">
        <Container className="wrap-content">
          <HeaderLandingPage />
        </Container>
      </div>
      <div sm="12" style={{ marginTop: "-120px" }}>
        <div>
          <QuoteLandingPage />
        </div>
      </div>
    </Fragment>
  );
};
export default Home;
